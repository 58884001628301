





















































import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import DatePickerField from '@/components/date-picker-field.vue';
import TimePickerField from '@/components/time-picker-field.vue';
import dayjs from '@/plugins/dayjs';

@Component({
  components: {
    DatePickerField,
    TimePickerField,
  },
})
export default class DateTimePickerField extends Vue {
  panel = -1;

  @VModel({ required: true, default: null })
  pickedDateTime!: Date | null;

  @Prop()
  label!: string;

  @Prop({ default: '280px' })
  minWidth!: string;

  @Prop({ default: '280px' })
  width!: string;

  @Prop()
  prependInnerIcon!: string;

  @Prop()
  dense!: boolean;

  pickedDate: Date | null = null;

  pickedTime: Date | null = null;

  onChangePickedDateTime(): void {
    if (this.pickedDate && this.pickedTime) {
      const newDate = dayjs(`${dayjs(this.pickedDate).format('YYYY-MM-DD')} ${this.pickedTime}:00`).toDate();
      this.pickedDateTime = newDate;
    } else {
      this.pickedDateTime = null;
    }
  }

  get formattedPeriod(): string | null {
    return this.pickedDateTime ? dayjs(this.pickedDateTime.toString()).format('DD/MM/YYYY HH:mm')
      : null;
  }
}
