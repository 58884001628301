





































































































import { Vue, Component, VModel, Watch } from 'vue-property-decorator';
import { VueMaskFilter } from 'v-mask';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CrmChatCloseForm from '@/components/crm/chat-close-form.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ConversationService from '@/services/crm/conversation.service';
import ConversationModel from '@/models/crm/conversation.model';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import OriginModel from '@/models/crm/origin.model';
import GroupModel from '@/models/crm/group.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import ContactModel from '@/models/crm/contact.model';
import SettingsModel from '@/models/crm/settings.model';
import SettingsService from '@/services/crm/settings.service';

interface IChatContactFormModel {
  description: string | null;
  mistake: boolean;
  justified: boolean;
  origin: OriginModel | null;
  historyType: HistoryTypeModel | null;
  groupArea: GroupModel | null;
  consolidateSale: boolean;
  contact: ContactModel | null;
}

@Component({
  components: {
    CrmChatCloseForm,
    AgGridWrapper,
    AgGridVue,
  },
})
export default class CrmChatCloseSteps extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @VModel()
  items!: ConversationModel[];

  selected: ConversationModel[] = [];

  closedIds: number[] = [];

  closeChatmodel: IChatContactFormModel = {
    description: null,
    historyType: null,
    origin: null,
    groupArea: null,
    contact: null,
    mistake: false,
    justified: false,
    consolidateSale: false,
  };

  conversationUserPermission!: ConversationUserPermissionsModel;

  settings: SettingsModel | null = null;

  btnSave = false;

  etapa1 = 1;

  etapa2 = 2;

  etapa3 = 3;

  btnEnabled = this.etapa1;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataUltimaMensagem', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.chats.grid.openDate')}`,
        colId: 'openDate',
        field: 'dataInicio',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataInicio;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.lastAttendance')}`,
        colId: 'lastAttendance',
        field: 'dataUltimaMensagem',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataUltimaMensagem || params.data.dataInicio || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactType')}`,
        colId: 'contactType',
        field: 'tipo',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data && params.data.tipo && params.data.tipo.toLowerCase().includes(ClientTypeEnum.Client)
              ? 'Cliente'
              : 'Prospect';
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.legalName')}`,
        colId: 'legalName',
        field: 'cliente.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.prospect) {
              return params.data.prospect.nome;
            }
            if (params.data.cliente) {
              return params.data.cliente.nome;
            }
            return params.data.nome;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.contato) {
              return params.data?.contato?.nome;
            }
            if (params.data.nomeContato && params.data.nomeContato.trim() !== '') {
              return params.data?.nomeContato;
            }
            const mask = params.data.numeroWhatsapp && params.data.numeroWhatsapp.length > 12
              ? '+## (##) #####-####'
              : '+## (##) ####-####';
            return VueMaskFilter(params.data?.numeroWhatsapp, mask);
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        maxWidth: 150,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.department')}`,
        colId: 'department',
        field: 'departamento.nome',
        flex: 0.5,
        sortable: true,
      },
    ],
  };

  @Watch('items', { immediate: true })
  onModelChange(): void {
    this.selected = this.items;
    this.grid?.api.selectAll();
  }

  async created(): Promise<void> {
    this.conversationUserPermission = await this.conversationService.getUserPermissions();
    this.settings = await this.settingsService.getSettings();
    this.selected = this.items;
    this.grid?.api.selectAll();
  }

  onNextStep(): void {
    if (this.selected.length === 0) {
      this.$notify.error('Selecione ao menos uma conversa para ser fechada!');
    } else if (this.btnEnabled === this.etapa2 && !this.validJustification) {
      this.$notify.error('Informe valores para os campos origem, grupo/área, tipo de histórico e/ou descrição!');
    } else {
      this.btnEnabled += this.etapa1;
    }
  }

  onPreviousStep(): void {
    this.btnEnabled -= this.etapa1;
  }

  onCellClicked(event: IGridCellEvent<ConversationModel>): void {
    if (this.grid && event.node.id) {
      this.grid.api.getRowNode(event.node.id)?.setSelected(!event.node.isSelected());
    }
  }

  onSelectionChanged(): void {
    if (this.grid?.api.getSelectedNodes()) {
      this.selected = this.grid?.api.getSelectedNodes().map((item) => item.data as ConversationModel);
    } else {
      this.selected = [];
    }
  }

  async onSave(): Promise<void> {
    const loader = this.$loading.show();
    this.btnSave = true;
    this.gridSettings.loading = true;
    const conversationIds = this.selected.map((select) => select.id);
    try {
      this.closedIds = await this.saveConversations(conversationIds);
    } catch (err) {
      console.log(err);
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
      this.$notify.success('Conversas fechadas com sucesso!');
      this.items = [];
      this.selected = [];
      this.closeChatmodel = {
        description: null,
        historyType: null,
        origin: null,
        groupArea: null,
        contact: null,
        mistake: false,
        justified: false,
        consolidateSale: false,
      };
      this.btnEnabled = this.etapa1;
      this.btnSave = false;
      this.$emit('complete', this.closedIds);
    }
  }

  get validJustification(): boolean {
    if (this.closeChatmodel.mistake || this.closeChatmodel.justified) {
      return true;
    }
    if (
      this.settings?.flagObrigaClassificacaoFechamentoConversa
      && (!this.closeChatmodel.historyType
        || !this.closeChatmodel.origin
        || !this.closeChatmodel.groupArea)
    ) {
      return false;
    }
    if (this.settings?.flagObrigaDescricaoFechamentoConversa && !this.closeChatmodel.description) {
      return false;
    }
    return true;
  }

  async saveConversations(ids: Array<number>): Promise<number[]> {
    const res = await this.conversationService.closeConversations(
      ids,
      this.closeChatmodel.mistake ? this.closeChatmodel.mistake : false,
      this.closeChatmodel.justified ? this.closeChatmodel.justified : false,
      this.closeChatmodel.description ? this.closeChatmodel.description : '',
      this.closeChatmodel.origin ? this.closeChatmodel.origin : undefined,
      this.closeChatmodel.historyType ? this.closeChatmodel.historyType : undefined,
      this.closeChatmodel.consolidateSale ? this.closeChatmodel.consolidateSale : false,
    );
    return res;
  }

  onCancel(): void {
    this.btnEnabled = this.etapa1;
  }

  get closeReview(): string {
    return this.$t('crm.view.closeChats.closeReview').toString();
  }

  get classifyClosingConversations(): string {
    return this.$t('crm.view.closeChats.classifyClosingConversations').toString();
  }

  get confirmBeforeClose(): string {
    return `${this.$t('crm.view.closeChats.confirmBeforeClose')}`;
  }

  get warningBeforeClose(): string {
    const conversations = this.selected.length.toString();
    return `${this.$t('crm.view.closeChats.warningBeforeClose', { conversations })}`;
  }

  get warningContinue(): string {
    return `${this.$t('crm.view.closeChats.warningContinue')}`;
  }

  get previous(): string {
    return `${this.$t('crm.view.closeChats.stepper.previous')}`;
  }

  get next(): string {
    return `${this.$t('crm.view.closeChats.stepper.next')}`;
  }

  get save(): string {
    return `${this.$t('crm.view.closeChats.stepper.save')}`;
  }
}
